<script>
export default {
  name: 'BusinessTTK',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <v-dialog
    v-model="show"
    :hide-overlay="false"
    persistent
    max-width="1000px"
  >
    <BusinessTTKContent v-if="show" :customer="{}" @close="$emit('close', true)" />
  </v-dialog>
</template>
